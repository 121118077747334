import React, { useState, createContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { auth, db } from "../firebase";

const defaultFirebaseContext = {
  user: null,
};

export const AuthContext = createContext(defaultFirebaseContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  const [isInitialized, setIsInitialized] = useState(false);

  const collectionPrefix = useMemo(
    () => (user ? `/regions/${user.claims.regionId}` : null),
    [user]
  );
  const jobsRef = useMemo(
    () => (collectionPrefix ? db.collection(`${collectionPrefix}/jobs`) : null),
    [collectionPrefix]
  );
  const techsRef = useMemo(
    () =>
      collectionPrefix ? db.collection(`${collectionPrefix}/techs`) : null,
    [collectionPrefix]
  );
  const shopsRef = useMemo(
    () =>
      collectionPrefix ? db.collection(`${collectionPrefix}/shops`) : null,
    [collectionPrefix]
  );

  useEffect(() => {
    return auth.onAuthStateChanged(async (u) => {
      if (u) {
        const idTokenResult = await u.getIdTokenResult();
        if (!idTokenResult.claims.shopUser) {
          setError("This is not a shop user's account.");
          await auth.signOut();
          return;
        }

        setError(null);

        setUser({
          ...u,
          claims: idTokenResult.claims,
        });
        setIsInitialized(true);
      } else {
        setUser(null);
        setIsInitialized(true);
      }
    });
  }, [setUser, setError]);

  return (
    <AuthContext.Provider
      value={{
        user,
        collectionPrefix,
        jobsRef,
        techsRef,
        shopsRef,
        initialized: isInitialized,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
